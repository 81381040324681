@font-face {
  font-family: 'Manrope';
  src: url('/assets/fonts/Manrope-Regular.woff2') format('woff2'),
    url('/assets/fonts/Manrope-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('/assets/fonts/Manrope-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/Manrope-SemiBold.woff') format('woff');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Extended';
  src: url('/assets/fonts/MonumentExtended-Bold.woff') format('woff');
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}
