
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















.lang__list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 1.3rem;
  line-height: 2rem;
}

.lang__item {
  opacity: 0.2;
  font-size: 2rem;
  font-weight: 700;

  &:not(:last-of-type) {
    margin-right: 1rem;
  }

  &.active {
    opacity: 1;
  }

  a {
    color: currentColor;
    text-decoration: none;
    text-transform: uppercase;
  }
}
