.expand-toggle {
  position: relative;
  width: 100%;
  margin: 0;
  padding-right: 0;
  padding-left: 0;
  color: currentColor;
  background-color: transparent;
  border-width: 0;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
  }

  &[open] {
    .expand-toggle__plus {
      transform: rotate(135deg);
      transition-timing-function: $ease-out-quart;
    }
  }
}

.expand-toggle__content {
  @include liquid(padding-top, 20px, 40px);
  @include liquid(padding-bottom, 20px, 40px);

  &::marker,
  &::-webkit-details-marker {
    display: none;
  }

  width: 100%;
  list-style: none;
}

// Display flex doesn't work on summary elements in Safari
.expand-toggle__content-wrapper {
  display: flex;
  align-items: center;
  text-align: left;
}

.expand-toggle__icon {
  width: clamp(2.5rem, 3.571vw, 6rem);
  height: clamp(2.5rem, 3.571vw, 6rem);
  fill: $c-main;
  margin-right: clamp(1rem, 1.19vw, 2rem);
}

.expand-toggle__label__small {
  @include liquid(margin-left, 5px, 10px);

  flex-shrink: 0;
  color: $c-main;
  font-size: 1.2rem;
  line-height: 1;
  vertical-align: super;
}

.expand-toggle__plus {
  width: clamp(2rem, 1.904vw, 3.2rem);
  height: clamp(2rem, 1.904vw, 3.2rem);
  margin-left: auto;
  flex-shrink: 0;
  transition: transform 500ms $ease-in-quart;
}

.expand__content {
  @include liquid(margin-bottom, 20px, 40px);
}
