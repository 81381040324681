
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























.curtain::v-deep {
  position: relative;
  max-width: 100%;

  &.button,
  &[class*='button--'] {
    white-space: normal;
  }

  &:hover:not(:disabled) {
    .curtain__active {
      clip-path: inset(
        -1% -1% -1% -1%
      ); // -1% to prevent horizontal line of pixels in some cases
    }
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.loading {
    cursor: waiting;

    .curtain__label {
      opacity: 0;
    }
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.curtain__inner {
  width: 100%;
}

.curtain__active {
  position: absolute;
  top: 0;
  left: 0;
  clip-path: inset(
    -1% 100% -1% 0
  ); // -1% to prevent horizontal line of pixels in some cases
  transition: clip-path $transition-duration $easing;
}
