$gutter: 1.5rem;

.news-listing {
  display: flex;
  flex-wrap: wrap;
  justify-items: space-between;
  margin-right: -$gutter;
  margin-left: -$gutter;
  padding-left: 0;
  list-style: none;
}

.news-listing__item {
  width: 33%;
  padding-right: $gutter;
  padding-left: $gutter;
}
