
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























































































.header {
  position: absolute;
  z-index: $z-index-header;
  top: 0;
  right: 0;
  left: 0;
  transition: all $easing $transition-duration;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: $overline-height;
    background-color: $c-main;
  }

  &::after {
    content: '';
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height-large;
    transition: all $easing $transition-duration;
    transform-origin: 0 0;
  }

  &__content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: $header-content-padding 0;
    transition: padding $easing $transition-duration;
  }

  &__burger {
    position: relative;
    width: 4rem;
    margin: 0;
    padding: 0;
    color: currentColor;
    background: transparent;
    border-width: 0;
    cursor: pointer;

    @include mq($from: s) {
      margin: 0 0 0 4rem;
    }

    &__line {
      width: 100%;
      height: 0.2rem;
      margin-bottom: 0.6rem;
      background: currentColor;
      pointer-events: none;
      transform-origin: 0 0;
      transition: transform $easing $transition-duration;

      &:last-child {
        margin-bottom: 0;
        transform-origin: 0 0;
        transform: scaleX(0.5);
      }
    }
  }

  &.light {
    color: $c-text-light;
  }

  &.hidden {
    position: fixed;
    transform: translateY(-100%);
    background-color: $c-surface-dark;

    &::after {
      transform: scaleY(2);
    }
  }

  &.sticky,
  &.megamenu-opened,
  // &:not(.pristine):focus-within, // Keep header visible after clicking the close button
  &:not(.pristine):hover {
    position: fixed;
    transform: translateY(0%);
    color: $c-text-light;
    background-color: $c-surface-dark;

    &::after {
      transform: scaleY(1);
    }

    .logo {
      .text {
        fill: $c-text-light;
      }
    }
  }

  &.sticky,
  &:not(.pristine):focus-within,
  &:not(.pristine):hover {
    .header__content {
      padding: $header-sticky-content-padding 0;
    }
  }

  &.megamenu-opened {
    .header__burger {
      margin-right: var(--scrollbar-width);

      .header__burger__line {
        &:nth-child(1) {
          transform: rotate(21deg) scaleX(1.1);
        }

        &:nth-child(2) {
          transform: scaleX(0);
        }

        &:nth-child(3) {
          transform: rotate(-21deg) scaleX(1.1);
        }
      }
    }

    .quote {
      // @TODO: Animation transition
      // Do not use display: none because it will mess up elements flow
      visibility: hidden;
    }

    .logo {
      opacity: 0;

      @include mq($from: m) {
        opacity: 1;

        .text {
          opacity: 0;
        }
      }
    }
  }

  &:not(.sticky).pristine {
    background-color: transparent;
  }
}

.logo {
  position: relative;
  margin-right: auto;
  transition: all 250ms $ease-out-quad;

  .svg {
    display: block;
    height: 3.2rem;

    .flame {
      fill: $c-main;
    }

    .text {
      display: none;
      fill: currentColor;

      @include mq($from: s) {
        display: block;
      }
    }

    @include mq($from: s) {
      width: 18.4rem;
    }
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &.is-home.is-below-threshold {
    .text {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.quote {
  display: none;

  @include mq($from: s) {
    display: block;
  }
}
