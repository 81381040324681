
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































































































@import './src/styles/base/spacing';

.megamenu-logo-container {
  @include mq($from: m) {
    display: none;
  }
}

.megamenu-logo {
  margin: calc(#{$overline-height} + #{$header-content-padding}) 0 0;
  height: 3.2rem;
  top: 10px;
  left: 10px;
  fill: $c-main;
  width: 18px;

  @include mq($from: s) {
    // Add extra margin to getter closer to header height that contains button
    margin: calc(#{$overline-height} + #{$header-content-padding} + 10px) 0 0;
  }
}

.sticky {
  .megamenu-logo {
    margin: calc(#{$overline-height} + #{$header-sticky-content-padding}) 0 0;

    @include mq($from: s) {
      // Add extra margin to getter closer to header height that contains button
      margin: calc(
          #{$overline-height} + #{$header-sticky-content-padding} + 10px
        )
        0 0;
    }
  }
}

.megamenu__background,
[class*='megamenu__background--'] {
  transform-origin: 0 0;
  background: $c-surface-dark;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  pointer-events: none;

  &[class*='--red'] {
    background: $c-main;
  }
}

.megamenu {
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  color: $c-text-light;
  transform-origin: 0 0;

  &.opened {
    overflow: auto;
  }

  .megamenu__section {
    @include mq($until: m) {
      margin-bottom: 6rem;
    }
  }
}

.megamenu__mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: grid;
  place-items: center;
  overflow: auto;

  > .row {
    width: 100%;
  }
}

.megamenu__inner {
  align-items: stretch;

  // Prevent megamenu and logo superposition. This might happen on small resolutions when an alert banner is displayed
  padding-top: 11rem;

  // Only remove padding for screen larger than M AND higher than 700px
  @include mq($from: m, $and: '(min-height: 700px)') {
    padding-top: 0;
  }
}

.secondary-menu-container {
  display: flex;
  flex-direction: column;
}

.mb-menu-secondary {
  @include liquid(margin-bottom, 50px, 80px);
}

.socials-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  margin-bottom: 4rem;

  @include mq($from: m, $and: '(min-height: 700px)') {
    position: absolute;
    bottom: 4rem;
    width: 29.1666666667%;
  }
}

::v-deep {
  .secondary-menu-container {
    // Align technical support CTA
    .button--has-curtain--dark {
      @include liquid(font-size, 15px, 18px);

      align-self: flex-start;
    }
  }

  .menu--primary {
    @include liquid(margin-bottom, 56px, 97px);

    .menu__item {
      @include liquid(font-size, 48px, 80px);

      font-weight: 600;
      line-height: 1.2;

      &:not(:last-of-type) {
        margin-bottom: 2rem;
      }
    }
  }

  .menu--secondary {
    column-count: 2;
    column-gap: 0;
    font-weight: 600;

    .menu__item {
      @include liquid(font-size, 15px, 18px);
      line-height: 1.3;

      &:not(:last-of-type) {
        margin-bottom: 1.5rem;

        @include mq($from: m) {
          margin-bottom: 1rem;
        }
      }
    }

    @include mq($from: m) {
      column-count: 1;
    }

    .curtain__active {
      background-color: $c-black;
      color: $c-main;
    }
  }

  .big-contact-cta {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mq($from: xs) {
      flex-direction: row;
      align-items: center;
    }

    @include mq($from: m) {
      margin-bottom: 0;
    }

    .curtain__active {
      background: $c-surface-dark;
      color: $c-main;
    }
  }

  .big-contact-cta__label {
    @include liquid(font-size, 20px, 30px);

    @include mq($from: xs) {
      margin-bottom: 0;
      width: 50%;
    }
  }

  .newsletter {
    .spinner {
      right: 2rem;
    }
  }

  .lang__list {
    font-size: 1ch;
  }

  .lang__item:not(.active) {
    opacity: 0.4;
  }

  .float-label {
    @include liquid(--fs, 15px, 18px);
  }

  .socials {
    margin: 0;
  }
}
