.wysiwyg,
[class*='wysiwyg--'] {
  line-height: 2;

  h1,
  .h1,
  h2,
  .h2 {
    &:not(:first-of-type) {
      @extend .mt-xxl;
    }

    @include fluid(
      margin-bottom,
      (
        xxs: 30px,
        m: 50px,
      )
    );
  }

  h3,
  .h3,
  h4,
  .h4 {
    @include fluid(
      margin-bottom,
      (
        xxs: 20px,
        m: 30px,
      )
    );
  }

  p,
  .curtain,
  .icon-link,
  ul,
  ol {
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, m: 30px));
  }

  hr {
    // prettier-ignore
    @include fluid(margin-top, (xxs: 20px, m: 50px));
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, m: 50px));

    height: 2px;
    background: $c-black;
    border: 0;
  }

  ul,
  ol {
    margin-top: 0;
  }

  ol {
    list-style: none;
    counter-reset: li;
  }

  ul li {
    position: relative;
    padding-bottom: 1.5rem;
    line-height: 1.4em;
    list-style-type: none;

    &::before {
      content: '';
      position: absolute;
      top: 0.6em;
      left: -20px;
      width: 5px;
      height: 5px;
      background: $c-main;
      border-radius: 50%;
    }
  }

  ol li {
    position: relative;
    padding-bottom: 1.5rem;
    counter-increment: li;

    &::before {
      content: counter(li) '. ';
      display: inline-flex;
      margin-right: 1rem;
      color: $c-black;
    }
  }

  a {
    color: currentColor;
    text-decoration: none;
  }

  .small {
    font-size: 1.5rem;
    opacity: 0.4;
  }

  .table-wrapper {
    overflow-x: auto;
  }

  table {
    min-width: 76rem;

    thead {
      @include fluid(
        font-size,
        (
          xxs: 9px,
          xl: 11px,
        )
      );

      color: $c-black;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    tbody {
      @include fluid(
        font-size,
        (
          xxs: 15px,
          xl: 18px,
        )
      );

      color: $c-gray-darker;
      line-height: 3.2rem;
    }

    tr {
      border-bottom: 1px solid $c-gray-light;
    }

    td {
      padding: 3rem 0;
    }
  }

  a:not(.icon-link) {
    text-decoration: underline;
  }

  > :last-child {
    margin-bottom: 0;
  }

  img {
    &.aligncenter {
      margin: auto;
    }
  }
}
