.menu,
[class*='menu--'] {
  margin: 0;
  padding: 0;
  list-style-type: none;

  .curtain {
    display: inline-block;
  }

  .curtain__inner {
    display: inline-block;
    padding-right: 0.5em;
    line-height: initial;
  }

  .curtain__active {
    color: $c-text-dark;
    background-color: $c-main;
  }
}
