
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































.newsletter__form {
  position: relative;
  margin-bottom: 1.2rem;
}

.newsletter__submit {
  position: absolute;
  top: 50%;
  right: 0;
  margin: 0;
  padding: 0 2rem;
  color: currentColor;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: inherit;
  text-transform: uppercase;
  background: transparent;
  border-width: 0;
  cursor: pointer;
  transform: translateY(-50%);
}

.newsletter__description {
  font-size: 1.3rem;
  opacity: 0.4;
}

.request-message {
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 800;
}

::v-deep {
  .spinner {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
