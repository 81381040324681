
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















.link {
  display: inline-flex;
  align-items: center;
  font-size: clamp(1.3rem, 0.892vw, 1.5rem);
  font-weight: 600;

  &:hover,
  &:focus {
    .link__icon {
      background-color: $c-main;
    }

    .link__svg {
      transform: translateX(0%);
    }
  }

  &__label {
    margin-right: 1rem;
  }

  &__icon {
    display: flex;
    overflow: hidden;
    width: 2rem;
    height: 2rem;
    white-space: nowrap;
    background-color: currentColor;
    border-radius: 50%;
    transition: backgound-color $transition-duration $easing;
  }

  &__svg {
    display: block;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: transform $transition-duration $easing;
    fill: $c-text-light;
  }
}
