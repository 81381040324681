.home {
  // Only on homepage, hide header elements by default because we may animate their entrance with JS
  [data-header-element] {
    opacity: 0;
    transform: translateY(15px);

    @media (prefers-reduced-motion) {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .banner-outter {
    clip-path: inset(0% 0% 100% 0%);

    @media (prefers-reduced-motion) {
      clip-path: inset(0% 0% 0% 0%);
    }
  }
}
