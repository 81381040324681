$heading-max-width: 68%;

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  &.max-width,
  &[class*='--max-width'] {
    max-width: $heading-max-width;
  }
}

h1,
.h1,
[class*='h1--'] {
  margin: 0 0 6rem;
  font-size: clamp(3rem, 3.571vw, 6rem);
  font-weight: 600;
  line-height: 1.3;

  &[class*='--large'] {
    font-size: clamp(3rem, 7.17vw, 10rem);
    line-height: 1.2;

    &[class*='--max-width'] {
      max-width: 85.2%;
    }
  }
}

h2,
.h2,
[class*='h2--'] {
  margin: 0 0 4rem;
  font-size: clamp(2rem, 2.38vw, 4rem);
  font-weight: 600;
  line-height: 1.4;
}

h3,
.h3 {
  @include liquid(font-size, 18px, 30px);

  font-weight: 600;
  line-height: 1.5;
}

p {
  margin-bottom: 1em;
}

.baseline {
  font-family: $ff-alt;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
}

.title-baseline {
  position: relative;
  margin-bottom: 6.6rem;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
  }

  .baseline {
    display: inline-block; // Prevent margin collasping
    margin-bottom: 6.6rem;
  }
}

.alt-heading {
  @extend .mb-l;

  font-family: $ff-alt;
  font-size: 11px;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.page-title,
.page-subtitle {
  @include mq($until: l) {
    margin-bottom: 2rem;
  }
}
