.bleed-container {
  // Creates a new stacking context, allowing us to set the .bleed z-index to -1
  // in order for it to go underneath content bot not underneath background
  position: relative;
  z-index: 1;
}

// It will create an horizontal scrollbar because the width computation doesn't take
// into account the width of the vertical scrollbar.
// overflow-x should be applied on the body or at least a parent element to prevent this
.bleed,
[class*='bleed--'] {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: $c-main;

  @include mq($from: $content-width) {
    width: calc(100% + ((100vw - #{$actual-content-width}) / 2));
  }

  &[class*='--has-visual'] {
    background-color: $c-black;
  }
}

.bleed__visual {
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  user-select: none;
  pointer-events: none;
}
