.hero {
  position: sticky;
  top: 0;
  z-index: -1;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 90vh;
  color: $c-text-light;
  background-color: $c-surface-dark;

  > .row {
    width: 100%;
  }
}

.hero__visual-container {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.hero__visual {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 0%;
}
