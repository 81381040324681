.single {
  &__content-row {
    @include mq($until: xl) {
      flex-direction: column-reverse;
    }
  }

  &__subtitle {
    @extend .mb-l;
  }

  &__asides {
    @extend .mb-l;
  }

  &__aside {
    margin-bottom: 0;
  }

  &__visual-container {
    @extend .mb-xxxl;
  }

  &__visual {
    width: 100%;
  }
}
