
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































































































@import './src/styles/base/spacing';

.show-s {
  @include mq($until: s) {
    display: none;
  }
}

.logo {
  display: inline-block;

  .svg {
    display: block;
    width: 11.5rem;
    height: 2rem;
    fill: currentColor;
  }
}

.footer {
  padding: 4rem 0;
  background-color: $c-surface-light;

  @include mq($from: s) {
    padding: 12rem 0 4rem;
  }

  &__contact {
    font-size: 4rem;
    line-height: 1.2;

    &__label {
      display: inline-block;
      margin-bottom: 0.75em;
    }

    &__svg {
      display: inline-block;
    }
  }

  &__legal {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 1.1rem;
    line-height: 2rem;

    > * {
      opacity: 0.6;
    }

    p {
      margin: 0;
    }
  }

  &__epic-logo {
    color: inherit;
    text-decoration: none;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

.footer__copyright {
  display: flex;
  align-items: center;

  button {
    cursor: pointer;
    margin-left: 2rem;
    padding: 0;
    background: transparent;
    border: 0;
  }
}

.button {
  width: 100%;
}

::v-deep {
  .menu,
  [class*='menu--'] {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .menu__item {
      margin-bottom: 1.5rem;
      font-weight: 400;
      line-height: 1;

      a {
        color: currentColor;
        font-weight: inherit;
        text-decoration: none;
      }

      .curtain__active {
        background-color: $c-surface-light;
        color: $c-main;
      }
    }

    &[class*='--primary'] {
      .menu__item {
        font-weight: 800;

        @include mq($from: xs) {
          margin-bottom: 2rem;
        }

        a {
          font-size: clamp(1.5rem, 1.19vw, 2rem);
        }
      }
    }

    &[class*='--secondary'] {
      a {
        font-size: 1.5rem;
      }
    }
  }

  .big-contact-cta {
    @extend .mb-xxl;

    @include mq($from: xl) {
      margin-bottom: 0;
    }

    .curtain__active {
      background: $c-surface-light;
      color: $c-main;
    }
  }

  // Override some of the input styles to better fit within the footer
  .newsletter {
    .float-label__label {
      left: 0;
      font-size: 1.5rem;
    }

    .float-label__input {
      padding: 1rem 0;
      font-size: 1.5rem;
      line-height: 1;

      &::first-line {
        font-size: 1.5rem;
      }

      &:focus,
      &.has-value {
        + .float-label__label {
          transform: translate(0%, -160%) scale(0.75);
        }
      }
    }
  }

  .newsletter__submit {
    padding-right: 0;
  }
}
