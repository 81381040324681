body {
  --scrollbar-width: #{$scrollbar-width-default};

  color: $c-text-dark;
  font-family: #{$ff-default};
  font-size: $body-text;
  font-weight: 400;
  line-height: 1.2;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  @include mq(m) {
    font-size: $body-text-m;
  }
}

html {
  font-size: calc(1em * 0.625);

  //Avoid typekit FOUT
  &.js {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.wf-active,
    &.wf-inactive,
    &.ready {
      opacity: 1;
    }
  }
}

// SVG sprite
body > svg {
  display: none;
}

// RWD
img {
  max-width: 100%;
  height: auto;
}

// NO SCROLL
.no-scroll {
  overflow-y: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding-right: var(--scrollbar-width);
}

// VARIOUS
hr {
  height: 1px;
  background-color: $c-gray-darker;
  border: 0;
}

.is-hidden {
  visibility: hidden;
}

.o-h {
  overflow: hidden;
}
