
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































.banner-outter {
  @include liquid(font-size, 15px, 20px);

  overflow: hidden;
  margin-top: -0.6rem; // Hide overline
  color: $c-text-light;
  background-color: $c-main;
  transition: height 250ms $easing;
}

.banner {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.9rem 3.2rem 0.9rem 0;

  @include mq($from: l) {
    flex-direction: row;
    align-items: center;
  }
}

.banner__content {
  margin-bottom: 1rem;
  line-height: 1.4;

  @include mq($from: l) {
    margin: 0 5.5rem 0 1rem;
  }
}

.close-btn {
  position: absolute;
  top: 50%;
  right: 0;
  padding: 0;
  background-color: transparent;
  border-width: 0;
  transform: translateY(-50%);
  cursor: pointer;

  .close-btn__icon {
    @include liquid(width, 20px, 32px);
    @include liquid(height, 20px, 32px);

    display: block;
    transform: rotate(45deg);
    transition: transform 500ms $ease-in-cubic;
  }

  &:hover {
    .close-btn__icon {
      transition-timing-function: $ease-out-cubic;
      transform: rotate(225deg);
    }
  }
}

::v-deep {
  .banner {
    .link {
      .link__icon {
        background-color: $c-text-dark;
      }

      &:hover,
      &:focus {
        .link__icon {
          background-color: $c-text-dark;
        }
      }
    }
  }
}
