<template>
  <!-- eslint-disable max-len -->
  <div class="spinner">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewbox="0 0 16 16"
      preserveAspectRatio="xMidYMid meet"
      class="spinner__inner"
    >
      <path
        d="M12.9 3.1c1.3 1.2 2.1 3 2.1 4.9 0 3.9-3.1 7-7 7s-7-3.1-7-7c0-1.9 0.8-3.7 2.1-4.9l-0.8-0.8c-1.4 1.5-2.3 3.5-2.3 5.7 0 4.4 3.6 8 8 8s8-3.6 8-8c0-2.2-0.9-4.2-2.3-5.7l-0.8 0.8z"
      ></path>
    </svg>
  </div>
</template>

<style>
.spinner {
  display: grid;
  place-items: center;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.spinner__inner {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'spinner',
})
</script>
