$padding-x: 1.5rem;
$padding-y: 4rem;

.button,
[class*='button--'] {
  display: inline-block;
  padding: $padding-x $padding-y;
  color: currentColor;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background: transparent;
  border: 1px currentColor solid;
  cursor: pointer;

  &[class*='--has-curtain'] {
    padding: 0;

    .curtain__inner {
      display: inline-block;
      padding: $padding-x $padding-y;
    }

    &[class*='--dark'] {
      .curtain__active {
        color: $c-text-dark;
        background-color: $c-surface-light;
      }
    }

    &[class*='--light'] {
      .curtain__active {
        color: $c-text-light;
        background-color: $c-surface-dark;
      }
    }

    &[class*='--fullwidth'] {
      width: 100%;
    }
  }
}
