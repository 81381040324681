
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





.curtain {
  position: fixed;
  z-index: $z-index-header;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: $c-black;
  transform: scaleX(0);
}
