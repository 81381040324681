.load-more-container {
  display: flex;
  justify-content: flex-end;

  .load-more {
    font-weight: 800;
    background: $c-white;

    .number {
      color: $c-main;
      font-size: 1.2rem;
      vertical-align: super;
      font-weight: 400;
    }
  }
}
