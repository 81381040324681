
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























// Defaults
.action,
[class*='action--'] {
  color: $c-black;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

// Links
[class*='action--'][class*='--link'] {
  color: $c-black;
  text-decoration: none;

  &[class*='--negative'] {
    color: $c-white;
  }
}

// Buttons (tag OR .btn)
// button.action,
// button[class*='action--'],
[class*='action--'][class*='--btn'] {
  &:disabled {
    opacity: 0.5;
  }
}

.action__icon {
  fill: currentColor;
}
