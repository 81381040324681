.surface,
[class*='surface--'] {
  &[class*='--light'] {
    background-color: $c-surface-light;
    color: $c-text-dark;
  }

  &[class*='--accent'] {
    background-color: $c-surface-light-accent;
    color: $c-text-dark;
  }

  &[class*='--dark'] {
    background-color: $c-surface-dark;
    color: $c-text-light;
  }
}
