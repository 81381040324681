
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















.socials {
  display: flex;
  margin: 0 0 4rem;
  padding: 0;
  list-style-type: none;

  @include mq($from: s) {
    margin: 0;
  }

  &__item {
    :not(:last-child) {
      margin-right: 1rem;
    }
  }

  &__link {
    color: currentColor;
    text-decoration: none;
  }

  &__svg {
    display: block;
    width: 3rem;
    height: 3rem;
    fill: currentColor;
  }
}
