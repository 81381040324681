
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















.big-contact-cta {
  --progress: 1;

  @include liquid(padding-top, 22px, 52px);

  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 1rem;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transform-origin: 0 0;
    transform: scaleX(var(--progress));
  }

  &__label {
    display: inline-block;
    margin-bottom: 0.75em;
    font-size: 3.6em;
    line-height: 1.2;
  }

  &__svg {
    fill: currentColor;
    display: inline-block;
  }

  &:hover,
  &:focus {
    ::v-deep {
      .curtain__active {
        clip-path: inset(
          -1% -1% -1% -1%
        ); // -1% to prevent horizontal line of pixels in some cases
      }
    }
  }
}

::v-deep {
  .curtain {
    display: inline-block;
  }
}
